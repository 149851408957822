const getStateFromStateCode = function (stateCode) {
  switch (stateCode) {
    case "AZ":
      return "Arizona";
    case "CA":
      return "California";
    case "ID":
      return "Idaho";
    case "NV":
      return "Nevada";
    case "NM":
      return "New Mexico";
    case "OR":
      return "Oregon";
    case "TX":
      return "Texas";
    case "UT":
      return "Utah";
    case "WA":
      return "Washington";
    default:
      return null;
  }
};

export default getStateFromStateCode;
