function getAddressComponentsByType(addressComponents, type) {
  // Create temporary array to put the objects that match our filter.
  let filteredAddressComponents = [];
  for (let i = 0; i < addressComponents.length; i++) {
    if (addressComponents[i].types.length > 0 && addressComponents[i].types.indexOf(type) >= 0) {
      // Object matches the filter, add it to our temporary array.
      filteredAddressComponents.push(addressComponents[i]);
    }
  }
  // Return the results.
  return filteredAddressComponents;
}

export default getAddressComponentsByType;
