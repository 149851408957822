import React, { useState, useEffect } from "react";

import GoogleMapReact from "google-map-react";
import GoogleMapMarker from "./google-map-marker";

const googleMapsApiKey = "AIzaSyDfp-9CfXxw-p-tC2tJVSikB5WnFisuYZ4";

const GoogleMapSimple = (props) => {
  const [map, setMap] = useState(null);
  let markers = [];

  const focusToMapMarkers = () => {
    if (map !== null && window) {
      const markers = props.markers;
      if (markers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < markers.length; i++) {
          bounds.extend(new window.google.maps.LatLng(markers[i].lat, markers[i].lng));
        }
        map.fitBounds(bounds);

        if (markers.length === 1) {
          // If there is only one marker, it zooms in too close; zoom out a little bit.
          map.setZoom(16);
        }
      }
    }
  };
  const createLatLng = (lat, lng) => {
    return new window.google.maps.LatLng(lat, lng);
  };

  const createNumberedMapMarker = (lat, lng, number, onClick) => {
    const image = {
      url: "/images/map-marker.png",
      size: {
        width: 46,
        height: 58
      },
      labelOrigin: {
        x: 23,
        y: 24
      }
    };

    const marker = new window.google.maps.Marker({
      position: createLatLng(lat, lng),
      label: {
        text: "" + number,
        color: "#fff",
        fontSize: "1.25rem",
        fontWeight: "700"
      },
      map: map,
      icon: image
    });
    marker.addListener("click", () => {
      if (typeof onClick === "function") {
        onClick(number, marker);
      }
    });
    if (typeof props.onMarkerCreated === "function") {
      props.onMarkerCreated(marker);
    }
    return marker;
  };
  //To do: combine common functionalities in one function for numbered and non numbered markers
  const createMapMarker = (lat, lng, number, onClick) => {
    const image = {
      url: "/images/wafd-gmap-pin.png",
      scaledSize: {
        width: 40,
        height: 50
      }
    };

    const marker = new window.google.maps.Marker({
      position: createLatLng(lat, lng),
      map: map,
      icon: image
    });
    marker.addListener("click", () => {
      if (typeof onClick === "function") {
        onClick(number, marker, map);
      }
    });
    if (typeof props.onMarkerCreated === "function") {
      props.onMarkerCreated(marker);
    }
    return marker;
  };

  useEffect(() => {
    if (typeof props.setGoogleMapInstance === "function") {
      props.setGoogleMapInstance(map);
    }
    return () => {
      if (typeof props.setGoogleMapInstance === "function") {
        props.setGoogleMapInstance(null);
      }
    };
  }, [map]);

  useEffect(() => {
    return () => {
      if (markers.length > 0) {
        markers.forEach(function (marker) {
          if (marker) {
            marker.setMap(null);
          }
        });
        markers = [];
      }
    };
  });

  return (
    <div style={{ height: props.height, width: props.width, backgroundColor: "LightSkyBlue" }}>
      {/* https://github.com/google-map-react/google-map-react/blob/master/API.md */}
      {/* libraries option was added in 2.1.0 per https://github.com/google-map-react/google-map-react/issues/922#issuecomment-683502907 */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsApiKey, language: props.lang, region: "US", libraries: ["places"] }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          setMap(map);
          if (props.markers.length > 0 && props.type === "branchSearch") {
            props.markers.map((marker) => {
              const _gmMarker = createMapMarker(marker.lat, marker.lng, marker.number, marker.onClick);
              markers.push(_gmMarker);
            });
          }
        }}
      >
        {props.markers.length > 0 && props.type === "branchSearch"
          ? map &&
            props.markers.map((marker) => {
              const _gmMarker = createMapMarker(marker.lat, marker.lng, marker.number, marker.onClick);
              markers.push(_gmMarker);
              return null;
            })
          : props.markers.map((marker) => {
              //console.log("sending marker: ", marker);
              if (typeof marker.number !== "undefined") {
                if (map) {
                  const _gmMarker = createNumberedMapMarker(marker.lat, marker.lng, marker.number, marker.onClick);
                  markers.push(_gmMarker);
                }
                return null;
              } else {
                return <GoogleMapMarker key={marker.lat + "-" + marker.lng} {...marker} />;
                //To do: remove GoogleMapMarker Component from google map react and create marker for branch page as below using google map native marker api
                // const _gmMarker = createMapMarker(marker.lat, marker.lng);
                // markers.push(_gmMarker);
                // return null;
              }
            })}
      </GoogleMapReact>
      {focusToMapMarkers()}
    </div>
  );
};

// Default to Seattle Downtown branch - 425 Pike St. Seattle, WA 98101
GoogleMapSimple.defaultProps = {
  lang: "en",
  cookieName: "wafd.location",
  center: {
    // default to center of USA
    lat: 39.784986,
    lng: -100.54615
  },
  zoom: 6,
  height: "400px",
  width: "100%",
  title: "United States",
  markers: [],
  type: ""
};

export default GoogleMapSimple;
