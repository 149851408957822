import React from "react";

import Icon from "../custom-widgets/icon";

// Styles
import styles from "./google-map.module.scss";

// Images
import MarkerPin from "../../images/wafd-gmap-pin.png";

// NOTE: there is an known issue with the marker moving when zooming in/out because it is positioned at top: 0, left: 0.
// We are adjusting it to be better aligned with the bottom center of the marker image with the inlined style transformation to get it much closer.
// This could be fine tuned a little better, but zooming out far enough makes the marker's accuracy less critical.
const GoogleMapMarker = (props) => {
  let marker = props.marker;
  //console.log("GM marker data: ", marker);

  return (
    <div lat={marker.lat} lng={marker.lng}>
      {marker.icon === "wafd" ? (
        <img className={`${marker.iconClass} ${styles.wafdMapMarker}`} src={MarkerPin} alt="Map marker pin" />
      ) : (
        <Icon {...{ name: marker.icon, class: marker.iconClass }} />
      )}
      {marker.text && <span className={styles.wafdMapMarkerText}>{marker.text}</span>}
    </div>
  );
};
export default GoogleMapMarker;

// Default to Seattle Downtown branch - 425 Pike St. Seattle, WA 98101
GoogleMapMarker.defaultProps = {
  marker: {
    iconClass: "text-success mr-3", // text-success, text-danger
    icon: "wafd", // wafd, map-marker, map-marker-alt
    lat: 47.6104369,
    lng: -122.3356121,
    text: ""
  }
};
